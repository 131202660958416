import React, { useEffect, useRef } from "react";
import { Card as ICard } from "./index";

type Props = {
  card: ICard;
  focusedId: string | undefined;
  setFocused: (id: string | undefined) => void;
  updateAt: (id: string, updater: (card: ICard) => ICard) => void;
};

export function Card(props: Props) {
  const { card, focusedId, setFocused, updateAt } = props;
  const isFocused = focusedId === card.id;
  const isPlaceholder = card.id.startsWith("empty-");
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef.current && isFocused) {
      scrollIntoView(cardRef.current);
      cardRef.current?.focus();
    }
  }, [isFocused]);

  return (
    <div
      ref={cardRef}
      tabIndex={0}
      className={`card-element ${
        focusedId === card.id && !isPlaceholder ? "-focused" : ""
      } ${isPlaceholder ? "-placeholder" : ""}`}
      key={card.id}
      onBlur={() => {
        // setFocused(undefined);
      }}
      onClick={(event) => {
        if (isFocused) {
          setFocused(undefined);
          return;
        }
        setFocused(card.id);
      }}
    >
      <img
        className="image"
        width="568"
        height="800"
        src={card.path}
        alt={card.id}
      />

      {isPlaceholder && (
        <div className="placeholdertext">
          <div className="content">
            <p>On est pas tous câblés pareil !</p>

            <small>
              Et vous c’est quoi vos préférences ? Pour en savoir + :{" "}
              <ul>
                <li>
                  <a href="mailto:soledad.kaercher@gmail.com">
                    Soledad.Kaercher@gmail.com
                  </a>
                </li>
                <li>
                  <a href="mailto:edith.daudet@gmail.com">
                    Edith.Daudet@gmail.com
                  </a>
                </li>
              </ul>
            </small>
          </div>
        </div>
      )}

      {!isPlaceholder && (
        <div className="toggle-buttons">
          <button
            className={`toggle-button -nope ${
              card.choice === "nope" ? "-active" : ""
            }`}
            onClick={(event) => {
              updateAt(card.id, (card) => {
                return {
                  ...card,
                  choice: card.choice === "nope" ? "neutral" : "nope",
                };
              });
              event.stopPropagation();
            }}
            type="button"
          >
            <span className="icon">X</span> Pas du tout
          </button>
          <button
            className={`toggle-button -yep ${
              card.choice === "yep" ? "-active" : ""
            }`}
            onClick={(event) => {
              updateAt(card.id, (card) => {
                return {
                  ...card,
                  choice: card.choice === "yep" ? "neutral" : "yep",
                };
              });
              event.stopPropagation();
            }}
            type="button"
          >
            <span className="icon">♥</span> Totalement
          </button>
        </div>
      )}
    </div>
  );
}

function scrollIntoView(target: HTMLElement) {
  target.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
}
