type MappedObj<T, U> = {
  [K in keyof T]: U;
};

export function mapObj<Obj extends { [k: string]: T }, T, U>(
  obj: Obj,
  func: (val: T) => U
): MappedObj<Obj, U> {
  let result: any = {};
  for (const key in obj) {
    result[key] = func(obj[key]);
  }
  return result as MappedObj<Obj, U>;
}
