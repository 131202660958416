import React, { useState, useMemo } from "react";
import "./index.css";
import { SegmentedControl, Filter } from "./SegmentedControl/index";
import seedrandom from "seedrandom";
import logo from "../logo.png";
import path from "path";
import { Card } from "./Card";

const isRandomPickEnabled = false;

const getRandom = seedrandom(window.location.href);

function requireCards(isDemo: boolean = false): Cards {
  const context = (require as any).context("./assets");

  const keys = context.keys();

  return keys
    .sort(() => (getRandom() > 0.5 ? 1 : -1))
    .reduce((acc: Cards, file: string, index: number) => {
      const random = getRandom();
      const probability = 1.5 - (index * 3) / keys.length;
      const isEnabled = isDemo ? random < probability : true;

      if (isEnabled) {
        const id = path.basename(file, ".jpg");
        acc[id] = {
          id,
          path: context(file),
          choice: "neutral",
        };
      } else {
        const id = "empty-" + (random + index).toString(32);
        acc[id] = {
          id,
          path: require("./placeholder.png"),
          choice: "neutral",
        };
      }

      return acc;
    }, {});
}

export type Card = {
  id: string;
  path: string;
  choice?: keyof Filter;
};

type Cards = { [id: string]: Card };

type Props = { isDemo?: boolean };

export function CardsList(props: Props) {
  const { isDemo } = props;
  let allCards = useMemo(() => requireCards(isDemo), [isDemo]);
  const [filter, setFilter] = useState<Filter>({
    neutral: false,
    nope: false,
    yep: false,
  });
  const [cards, setCards] = useState<Cards>(allCards);
  const [focusedId, setFocused] = useState<string | undefined>(undefined);

  function updateAt(id: keyof Cards, fn: (card: Card) => Card) {
    setCards((c: Cards) => {
      if (!c[id]) return c;
      c = { ...c };
      c[id] = fn(c[id] as Card);
      return c;
    });
  }

  const cardsList: Card[] = Object.values(cards);
  const visibleCards = cardsList.filter((card) => {
    if (!filter.neutral && !filter.nope && !filter.yep) return true;
    if (filter.neutral && card.choice === "neutral") return true;
    if (filter.nope && card.choice === "nope") return true;
    if (filter.yep && card.choice === "yep") return true;
    return false;
  });
  const neutralCards = cardsList.filter((x) => x.choice === "neutral");
  const yepCards = cardsList.filter((x) => x.choice === "yep");
  const nopeCards = cardsList.filter((x) => x.choice === "nope");

  return (
    <>
      <header className="app-header">
        <img className="logo" src={logo} alt="Libérez votre potentiel" />
      </header>

      <SegmentedControl
        value={filter}
        onChange={setFilter}
        options={{
          nope: `X Pas du tout moi (${nopeCards.length})`,
          yep: `♥ Totalement moi (${yepCards.length})`,
          neutral: `? Les autres (${neutralCards.length})`,
        }}
      />

      {!isDemo && isRandomPickEnabled && (
        <button
          type="button"
          className="button-select-random"
          onClick={() => {
            setFilter({
              neutral: false,
              nope: false,
              yep: false,
            });

            setFocused(cardsList[randomInteger(cardsList.length)].id);
          }}
        >
          Piocher une carte au hasard
        </button>
      )}

      <div className="cards-list">
        {visibleCards.map((card) => (
          <Card
            key={card.id}
            card={card}
            focusedId={focusedId}
            setFocused={setFocused}
            updateAt={updateAt}
          />
        ))}
      </div>
    </>
  );
}

function randomInteger(max: number) {
  return Math.floor(Math.random() * max);
}
