import React from "react";
import "./style.css";
import { mapObj } from "../../libs/mapObj";

export type Filter = {
  neutral: boolean;
  yep: boolean;
  nope: boolean;
};

export type Options = {
  [key in keyof Filter]: string | undefined;
};

export function SegmentedControl({
  value,
  onChange,
  options,
}: {
  value: Filter;
  onChange: (value: Filter) => void;
  options: Options;
}): JSX.Element {
  const availableOptions = Object.keys(options) as (keyof Filter)[];
  return (
    <div className="segmented-control">
      {availableOptions
        .filter((key) => options[key])
        .map((id) => {
          const label = options[id];
          const selected = value[id];
          return (
            <button
              className={`button ${selected ? "-selected" : ""}`}
              key={id}
              onClick={() => {
                onChange({
                  ...(mapObj(value, () => false) as any),
                  [id]: !selected,
                });
              }}
            >
              {label}
            </button>
          );
        })}
    </div>
  );
}
