import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import Cookies from "js-cookie";

function readJwt(): { exp?: number } {
  try {
    return JSON.parse(atob(Cookies.get("jwt")?.split(".")[1] || ""));
  } catch {
    return {};
  }
}

const jwt = readJwt();
if (jwt?.exp) {
  const delay = jwt?.exp * 1000 - Date.now();
  setTimeout(() => window.location.reload(), delay);
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
