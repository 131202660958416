import React, { useState } from "react";
import seedrandom from "seedrandom";
import path from "path";
import "./index.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const getRandom = seedrandom(window.location.href);

type Map = {
  id: string;
  path: string;
};

type Maps = { [id: string]: Map };

function requireMaps(): Maps {
  const context = (require as any).context("./assets");

  return context
    .keys()
    .sort(() => (getRandom() > 0.5 ? 1 : -1))
    .reduce((acc: Maps, id: string) => {
      acc[id] = { id: path.basename(id, ".jpg"), path: context(id) };
      return acc;
    }, {});
}

const allMaps = requireMaps();

export function Maps() {
  return (
    <div className="maps-layout">
      <nav className="maps-sidenav">
        {Object.values(allMaps).map((map) => {
          return (
            <a key={map.id} href={`#${map.id}`}>
              <img src={map.path} alt={map.id} />
            </a>
          );
        })}
      </nav>
      <ul className="maps-list">
        {Object.values(allMaps).map((map) => (
          <MapItem key={map.id} map={map} />
        ))}
      </ul>
    </div>
  );
}

function MapItem({ map }: { map: Map }) {
  const [isFocused, setFocused] = useState(false);

  return (
    <li
      id={map.id}
      className={`map-item ${isFocused ? "-fullscreen" : ""}`}
      onClick={() => setFocused(true)}
    >
      <TransformWrapper
        wheel={{ wheelEnabled: false }}
        options={{ limitToWrapper: true }}
      >
        {({ zoomIn, zoomOut }: { zoomIn: () => void; zoomOut: () => void }) => (
          <>
            <div className="tools">
              <button
                onClick={(event) => {
                  (zoomOut as any)(event);
                  event.stopPropagation();
                }}
              >
                -
              </button>
              <button
                onClick={(event) => {
                  (zoomIn as any)(event);
                  event.stopPropagation();
                }}
              >
                +
              </button>
              {isFocused && (
                <button
                  style={{ marginLeft: "16px" }}
                  onClick={(event) => {
                    setFocused(false);
                    event.stopPropagation();
                  }}
                >
                  x
                </button>
              )}
            </div>
            <TransformComponent>
              <img src={map.path} alt={map.id} />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </li>
  );
}
