import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { Maps } from "./Maps";
import { CardsList } from "./CardsList";

export function App() {
  return (
    <div className="app-layout">
      <Switch>
        <Route path="/cartes-mentales">
          <Maps />
        </Route>

        <Route path="/cartes">
          <CardsList />
        </Route>

        <Route exact path="/">
          <CardsList isDemo />
        </Route>
      </Switch>
    </div>
  );
}
